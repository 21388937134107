import dynamic from 'next/dynamic';

const SidebarNotCollapsedItemCollapsePage = dynamic(() => import('./Page'));

import { SidebarNotCollapsedItemCollapsePagesProps } from './props';
import styles from './styles.module.scss';

export default function SidebarNotCollapsedItemCollapsePages({
  pages,
}: SidebarNotCollapsedItemCollapsePagesProps) {
  return (
    <div className={styles.container}>
      {pages.map(page => (
        <SidebarNotCollapsedItemCollapsePage
          page={page}
          key={`sidebar_not_collapsed_item_page_${page.label}`}
        />
      ))}
    </div>
  );
}
